@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

}
.markdown > * {
    all: revert;
    text-align: justify;
  }
